<template>
  <app-dialog v-model="open" dense stretch>
    <slot name="prepend-inner"></slot>
    <voucher-pdf-preview v-if="voucher && voucher.id" :voucher="voucher" />
  </app-dialog>
</template>

<script>
import VoucherPdfPreview from '@/shared/components/VoucherPdfPreview';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';

export default {
  name: 'VoucherPreviewDialog',

  mixins: [ShowDialogMixin],

  components: {
    VoucherPdfPreview
  },

  props: {
    voucher: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    open: {
      get() {
        return !!(this.value && this.voucher && this.voucher.id);
      },
      set() {
        this.$emit('input', false);
      }
    }
  }
};
</script>
