<template>
  <v-container
    justify="center"
    :class="{
      'align-start': isSafari
    }"
    fill-height
  >
    <div class="pdf__wrapper">
      <div class="pdf__preview">
        <p v-if="error">{{ $t('workQueue.previewNotAvailable') }}</p>
        <template v-else>
          <pdf
            :key="voucher.id"
            :annotation="true"
            :page="page"
            :src="source"
            :resize="true"
            @numpages="numPages = $event"
            @loading="loadingPreview = $event"
            @error="error = true"
          >
            <template slot="loading">
              <app-spinner class="pdf__spinner" />
            </template>
          </pdf>
          <v-pagination
            v-if="numPages > 1 && !loadingPreview"
            v-model="page"
            :length="numPages"
            total-visible="6"
            circle
            class="mt-3"
          />
        </template>
      </div>
    </div>
  </v-container>
</template>

<script>
import pdf from 'pdfvuer';
import 'pdfjs-dist/build/pdf.worker.entry';
import 'pdfvuer/dist/pdfvuer.css';

import { BASE_URL } from '@/plugins/axios';

export default {
  name: 'VoucherPdfPreview',

  components: { pdf },

  props: {
    voucher: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    page: 1,
    numPages: 1,
    pdfData: null,
    loadingPreview: false,
    error: false
  }),

  computed: {
    source() {
      return {
        url: this.voucher.source ? BASE_URL + this.voucher.source : '',
        httpHeaders: { Authorization: `Bearer ${this.$auth.token()}` }
      };
    }
  },

  watch: {
    'voucher.id': {
      handler() {
        if (!this.voucher.source) {
          this.pdfData = null;
          this.error = true;
          return;
        }

        this.error = false;
      },
      immediate: true
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  margin-top: 0.5rem;
  padding: 0;
  flex-grow: 1;
  max-width: 100%;
  max-height: 100%;
}
.pdf__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#app.v-application.mobile .pdf__wrapper {
  height: 100%;
}

.pdf__spinner {
  align-self: center;
  justify-self: center;
  margin: calc(50% - 25px) auto;
}

.pdf__preview {
  width: 100%;
  position: relative;
  align-self: center;
  justify-self: center;
  text-align: center;
  margin: 0 auto 2rem auto;

  ::v-deep .page {
    border: 1px solid black;
    overflow: hidden;
  }
}
</style>
