import RouteName from '@/statics/routeName';

export default {
  data: () => ({
    lastRoutePath: ''
  }),

  methods: {
    redirectToLastRoute() {
      this.$router.push(this.lastRoutePath || { name: this.defaultRoute.route });
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name && ![to.name, RouteName.LOGIN].includes(from.name)) {
        vm.lastRoutePath = from.fullPath;
      }
    });
  }
};
